var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-box"},[_c('front-header',{attrs:{"chooseTitle":_vm.present,"background":_vm.heabBackground,"positionShow":true,"fontcolor":'#333',"logo":'1'}}),_c('div',{staticClass:"index-content",attrs:{"id":"index"}},[_c('el-carousel',{attrs:{"height":_vm.carouselHeight}},[_c('el-carousel-item',[_c('img',{attrs:{"width":"100%","src":require("../../image/index/cn-banner-2.png"),"alt":""}})]),_c('el-carousel-item',[_c('img',{attrs:{"width":"100%","src":require("../../image/index/cn-banner-1.png"),"alt":""}})]),_c('el-carousel-item',[_c('img',{attrs:{"width":"100%","src":require("../../image/index/cn-banner-3.png"),"alt":""}})])],1)],1),_c('div',{staticClass:"insurance commonStyle"},[_vm._m(0),_vm._m(1),_c('div',[_c('img',{attrs:{"src":require("../../image/index/pic1.png"),"alt":""},on:{"click":function($event){return _vm.routerTolist('006141')}}}),_c('img',{attrs:{"src":require("../../image/index/pic2.png"),"alt":""},on:{"click":function($event){return _vm.routerTodetail('006142')}}}),_c('img',{attrs:{"src":require("../../image/index/pic3.png"),"alt":""},on:{"click":function($event){return _vm.routerTodetail('006152')}}}),_c('img',{attrs:{"src":require("../../image/index/pic4.png"),"alt":""},on:{"click":function($event){return _vm.routerTolist('006151')}}})])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('front-bottom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("保险服务")]),_c('div',[_vm._v("SERVICE")]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v(" 基层劳动者近3亿，风险高，保障低，社保覆盖相对有限；")]),_c('div',[_vm._v("对行业而言，人群分散触达难，缺乏适合这一人群的保险产品；")]),_c('div',[_vm._v("对受众而言，保险产品复杂难懂，投保不方便、理赔体验差。")]),_c('div',[_c('a',{staticStyle:{"cursor":"pointer"}},[_vm._v("查看更多>>")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"myAdvantage commonStyle2",attrs:{"id":"advantage"}},[_c('div',[_c('div',[_vm._v("我们的优势")]),_c('div')]),_c('div',[_c('div',[_c('img',{attrs:{"src":require("../../image/index/icon3.png"),"alt":""}}),_c('div',[_vm._v("技术支持安全护航")]),_c('div',[_c('div',[_vm._v("基于人工智能的数据化产品设计")])])]),_c('div',[_c('img',{attrs:{"src":require("../../image/index/icon2.png"),"alt":""}}),_c('div',[_vm._v("客户服务经验丰富")]),_c('div',[_c('div',[_vm._v("13年保险运营经验")]),_c('div',[_vm._v("多个省+服务网络")]),_c('div',[_vm._v("全方位服务")])])]),_c('div',[_c('img',{attrs:{"src":require("../../image/index/icon1.png"),"alt":""}}),_c('div',[_vm._v("赔付率高对客户有保障")]),_c('div',[_c('div',[_vm._v("在线报案理赔")]),_c('div',[_vm._v("理赔客服1对1服务")])])]),_c('div',[_c('img',{attrs:{"src":require("../../image/index/icon4.png"),"alt":""}}),_c('div',[_vm._v("高效服务团队")]),_c('div',[_c('div',[_vm._v("7*24小时提供服务")]),_c('div',[_vm._v("完善且有实力的技术团队")]),_c('div',[_vm._v("客服团队、理赔团队")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantageService commonStyle"},[_c('div',[_c('div',[_vm._v("优势服务")]),_c('div',[_vm._v("ADVANTAGE SERVICE")]),_c('div')]),_c('div',[_c('div',[_c('h3',[_vm._v("小额理赔当天达")]),_c('div',[_vm._v(" 小额案件免原件，"),_c('br'),_vm._v(" 24小时内赔付 ")])]),_c('div',[_c('img',{attrs:{"src":require("../../image/index/advantage1.png"),"alt":""}})])]),_c('div',[_c('div',[_c('img',{attrs:{"src":require("../../image/index/advantage2.png"),"alt":""}})]),_c('div',[_c('h3',[_vm._v("大额理赔现场查勘")]),_c('div',[_vm._v(" 重大案件协助处理，"),_c('br'),_vm._v(" 指导后续索赔 ")])])]),_c('div',[_c('div',[_c('h3',[_vm._v("AI核赔")]),_c('div',[_vm._v(" 智能云端、AI核赔、极致体验，"),_c('br'),_vm._v(" 微信小程序全程闭环完成理赔 ")])]),_c('div',[_c('img',{attrs:{"src":require("../../image/index/advantage3.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about commonStyle",attrs:{"id":"about"}},[_c('div',[_c('div',[_vm._v("关于我们")]),_c('div',[_vm._v("ABOUT US")]),_c('div')]),_c('div',[_c('div',[_c('span',[_vm._v("锐鹿保")]),_vm._v("是一家服务企业及个人的普惠保险平台，依托互联网信息技术，深挖企业运营场景风险和个人风险，从而提供专业性更强、成本更低、更有保障的保险产品和一站式风险解决方案。公司总部位于衡水，由保险公司核心团队 + 资深互联网专家组成，通过互联网思维方式和运作方式为保险行业带来产品创新与体验升级，共同构建服务于 "),_c('span',[_vm._v("通信电力、物流、制造业、科技服务、建筑施工")]),_vm._v("以及 "),_c('span',[_vm._v("医保特定领域人群")]),_vm._v("的保险科技新生态。 ")]),_c('img',{attrs:{"src":require("../../image/index/3.png"),"alt":""}}),_c('div')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news"},[_c('img',{attrs:{"src":require("../../image/index/news.png"),"alt":""}}),_c('div',[_c('h2',[_vm._v("锐鹿保新闻")]),_c('div'),_c('div',[_c('div',[_c('span',[_vm._v("后疫情时代，锐鹿保如何开启保险科技新生态？")]),_c('span',[_vm._v("2020-08-06 ")])]),_c('div',[_c('span',[_vm._v("锐鹿保召开首届互联网保险战略创新大会")]),_c('span',[_vm._v("2020-08-06")])]),_c('div',[_c('span',[_vm._v("锐鹿保四大优势打造保险科技新生态")]),_c('span',[_vm._v(" 2020-08-06")])]),_c('div',[_c('span',[_vm._v(" 锐鹿保召开首届互联网保险战略创新大会")]),_c('span',[_vm._v("2020-08-06")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"science commonStyle2",attrs:{"id":"science"}},[_c('div',[_c('div',[_vm._v("保险科技新生态")]),_c('div')]),_c('div',[_vm._v(" 锐鹿保以\"打造智能风险管理服务平台\"为战略原景,致力于实现科技赋能保险,保险赋能行业以及百姓的保险市场定位,聚焦当下传统保险变革走向互联网保险增量场景的大蓝海,以科技赋能,对保险供应链进行价值再造与重塑,细分深耕行业,建立完善的行业用户画像体系,挖掘行业多场景叠加的用户价值,以\"保险+科技”作为核心驱动力,拥有\"大数据一体化智能风险管理体系架构”,打造锐鹿生态云平台。 ")]),_c('div',[_c('div',[_c('img',{attrs:{"src":require("../../image/index/science1.png"),"alt":""}}),_c('div',[_vm._v("大数据")])]),_c('div'),_c('div',[_c('img',{attrs:{"src":require("../../image/index/science2.png"),"alt":""}}),_c('div',[_vm._v("云计算")])]),_c('div'),_c('div',[_c('img',{attrs:{"src":require("../../image/index/science3.png"),"alt":""}}),_c('div',[_vm._v("区块链")])]),_c('div'),_c('div',[_c('img',{attrs:{"src":require("../../image/index/science4.png"),"alt":""}}),_c('div',[_vm._v("人工智能")])])])])
}]

export { render, staticRenderFns }