<template>
    <div class="index-box">
        <front-header :chooseTitle="present" :background="heabBackground" :positionShow="true" :fontcolor="'#333'"
                      :logo="'1'"></front-header>
        <div class="index-content" id="index">
            <el-carousel :height="carouselHeight">
                <el-carousel-item >
                    <img width="100%" src="../../image/index/cn-banner-2.png" alt="">
                </el-carousel-item>
                <el-carousel-item >
                    <img width="100%" src="../../image/index/cn-banner-1.png" alt="">
                </el-carousel-item>
                <el-carousel-item >
                    <img width="100%" src="../../image/index/cn-banner-3.png" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="insurance commonStyle">
            <div>
                <div>保险服务</div>
                <div>SERVICE</div>
                <div></div>
            </div>
            <div>
                <div> 基层劳动者近3亿，风险高，保障低，社保覆盖相对有限；</div>
                <div>对行业而言，人群分散触达难，缺乏适合这一人群的保险产品；</div>
                <div>对受众而言，保险产品复杂难懂，投保不方便、理赔体验差。</div>
                <!--  @click="routerTolist()" -->
                <div><a style="cursor: pointer">查看更多>></a></div>
            </div>
            <div>
                <img src="../../image/index/pic1.png" @click="routerTolist('006141')" alt=""><img
                    src="../../image/index/pic2.png" @click="routerTodetail('006142')" alt=""><img
                    src="../../image/index/pic3.png" @click="routerTodetail('006152')" alt=""><img
                    src="../../image/index/pic4.png" @click="routerTolist('006151')" alt="">
            </div>
        </div>
        <div class="myAdvantage commonStyle2" id="advantage">
            <div>
                <div>我们的优势</div>
                <div></div>
            </div>
            <div>
                <div>
                    <img src="../../image/index/icon3.png" alt="">
                    <div>技术支持安全护航</div>
                    <div>
                        <div>基于人工智能的数据化产品设计</div>
                    </div>
                </div>
                <div>
                    <img src="../../image/index/icon2.png" alt="">
                    <div>客户服务经验丰富</div>
                    <div>
                        <div>13年保险运营经验</div>
                        <div>多个省+服务网络</div>
                        <div>全方位服务</div>
                    </div>
                </div>
                <div>
                    <img src="../../image/index/icon1.png" alt="">
                    <div>赔付率高对客户有保障</div>
                    <div>
                        <div>在线报案理赔</div>
                        <div>理赔客服1对1服务</div>
                    </div>
                </div>
                <div>
                    <img src="../../image/index/icon4.png" alt="">
                    <div>高效服务团队</div>
                    <div>
                        <div>7*24小时提供服务</div>
                        <div>完善且有实力的技术团队</div>
                        <div>客服团队、理赔团队</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="advantageService commonStyle">
            <div>
                <div>优势服务</div>
                <div>ADVANTAGE SERVICE</div>
                <div></div>
            </div>
            <div>
                <div>
                    <h3>小额理赔当天达</h3>
                    <div>
                         小额案件免原件，<br> 24小时内赔付
                    </div>
                </div>
                <div><img src="../../image/index/advantage1.png" alt=""></div>
            </div>
            <div>
                <div><img src="../../image/index/advantage2.png" alt=""></div>
                <div>
                    <h3>大额理赔现场查勘</h3>
                    <div>
                        重大案件协助处理，<br> 指导后续索赔
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <h3>AI核赔</h3>
                    <div>
                        智能云端、AI核赔、极致体验，<br> 微信小程序全程闭环完成理赔
                    </div>
                </div>
                <div><img src="../../image/index/advantage3.png" alt=""></div>
            </div>
        </div>
        <div class="about commonStyle" id="about">
            <div>
                <div>关于我们</div>
                <div>ABOUT US</div>
                <div></div>
            </div>
            <div>
                <div><span>锐鹿保</span>是一家服务企业及个人的普惠保险平台，依托互联网信息技术，深挖企业运营场景风险和个人风险，从而提供专业性更强、成本更低、更有保障的保险产品和一站式风险解决方案。公司总部位于衡水，由保险公司核心团队
                    + 资深互联网专家组成，通过互联网思维方式和运作方式为保险行业带来产品创新与体验升级，共同构建服务于 <span>通信电力、物流、制造业、科技服务、建筑施工</span>以及 <span>医保特定领域人群</span>的保险科技新生态。
                </div>
                <img src="../../image/index/3.png" alt="">
                <div></div>
            </div>
        </div>
        <div class="news">
            <img src="../../image/index/news.png" alt="">
            <div>
                <h2>锐鹿保新闻</h2>
                <div></div>
                <div>
                    <div><span>后疫情时代，锐鹿保如何开启保险科技新生态？</span><span>2020-08-06  </span></div>
                    <div><span>锐鹿保召开首届互联网保险战略创新大会</span><span>2020-08-06</span></div>
                    <div><span>锐鹿保四大优势打造保险科技新生态</span><span> 2020-08-06</span></div>
                    <div><span> 锐鹿保召开首届互联网保险战略创新大会</span><span>2020-08-06</span></div>
                </div>
            </div>
        </div>
        <div class="science commonStyle2" id="science">
            <div>
                <div>保险科技新生态</div>
                <div></div>
            </div>
            <div>
                锐鹿保以"打造智能风险管理服务平台"为战略原景,致力于实现科技赋能保险,保险赋能行业以及百姓的保险市场定位,聚焦当下传统保险变革走向互联网保险增量场景的大蓝海,以科技赋能,对保险供应链进行价值再造与重塑,细分深耕行业,建立完善的行业用户画像体系,挖掘行业多场景叠加的用户价值,以"保险+科技”作为核心驱动力,拥有"大数据一体化智能风险管理体系架构”,打造锐鹿生态云平台。
            </div>
            <div>
                <div><img src="../../image/index/science1.png" alt="">
                    <div>大数据</div>
                </div>
                <div></div>
                <div><img src="../../image/index/science2.png" alt="">
                    <div>云计算</div>
                </div>
                <div></div>
                <div><img src="../../image/index/science3.png" alt="">
                    <div>区块链</div>
                </div>
                <div></div>
                <div><img src="../../image/index/science4.png" alt="">
                    <div>人工智能</div>
                </div>

            </div>
        </div>
        <!-- <div class="cooperation commonStyle" id="cooperation">
            <div>
                <div>合作客户</div>
                <div>COOPRATIVE CUSTOMERS</div>
                <div></div>
            </div>
            <div>
                <img src="../../image/index2/rbao.png" alt="">
                <img src="../../image/index2/pan.png" alt="">
                <img src="../../image/index2/baigb.png" alt="">
                <img src="../../image/index2/huaan.png" alt="">
            </div>
        </div> -->
        <front-bottom></front-bottom>
    </div>
</template>

<script>
    import frontHeader from "../../components/front/frontHeader";
    import frontBottom from "../../components/front/frontBottom";

    export default {
        name: "index",
        components: {
            frontHeader,
            frontBottom
        },
        data() {
            return {
                heabBackground: 'transparent',
                present: 'index',//当前高亮
                carouselHeight:'660px'
            }
        },
        created() {
            if (this.$store.state.loginEquipment === 0) {
                this.heabBackground = 'transparent'
            } else {
                this.heabBackground = 'rgba(255,255,255,1)'
            }
            this.present = this.$route.params.present
        },
        mounted() {
            // 事件监听滚动条
            window.addEventListener("scroll", this.watchScroll);
            let anchorElement = document.getElementById(this.present)
            if (anchorElement) {
                anchorElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'});
            }
            //立即执行
            window.onresize = () => {
            return (() => {
                this.watchWidth();
            })();
            };
            this.watchWidth();
        },
        methods: {
            routerTolist: function (pro_num) {//有工种页转跳列表页
                this.$router.push({
                    name: 'employerInsurance',
                    query: {
                        pro_num: pro_num,
                    }
                })
            },
            routerTodetail: function (pro_num) {//没有工种页转跳详情页
                this.$router.push({
                    name: 'detail',
                    query: {
                        pro_num: pro_num,
                    }
                })
            },
            watchScroll: function () {

                let offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                if (offset > 50) {
                    this.heabBackground = '#fff'
                } else {
                    if (this.$store.state.loginEquipment === 0) {
                        this.heabBackground = 'transparent'
                    } else {
                        this.heabBackground = 'rgba(255,255,255,1)'
                    }
                }
            },
            watchWidth:function(){
                //当前window 宽
                let centerWidth = document.documentElement.scrollWidth;
                //最小宽度 1440
                this.carouselHeight = Math.ceil(centerWidth * 0.34) + 'px';
                
            }
        },
        watch: {
            present() {
                console.log(this.present)
            }

        }

    }
</script>

<style scoped lang="scss">
    @media screen and (max-width: 900px) {
        .index-box {
            width: 100%;
            // padding-bottom: 95vh;
            box-sizing: border-box;
            position: relative;
        }
        .index-content{
            margin-top:50px;
        }
        .commonStyle {
            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    height: 35px;
                    font-size: 25px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 130, 45, 1);
                    line-height: 24px;
                    margin-top: 15px;
                    width: 100%;
                    text-align: center;
                }

                & > div:nth-child(2) {
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    top: 24px;
                    height: 49px;
                    font-size: 34px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 185, 138, 0.2);
                    line-height: 24px;

                    & > div:nth-child(4) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 24px;
                    }
                }

                & > div:nth-child(3) {
                    width: 58px;
                    height: 4px;
                    background: rgba(255, 130, 45, 1);
                    margin-left: calc(50% - 29px);
                }
            }
        }
        .commonStyle2 {
            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    width: 100%;
                    text-align: center;
                    height: 35px;
                    font-size: 25px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 24px;
                    margin-top: 35px;
                }

                & > div:nth-child(2) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: rgba(255, 255, 255, 1);
                    margin-left: calc(50% - 29px);
                }
            }
        }
        .insurance {
            width: 90%;
            margin-left: 5%;

            & > div:nth-child(2) {
                margin-top: 12px;

                & > div {
                    width: 100%;
                    height: 26px;
                    text-align: center;
                    font-size: 11px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 30px;
                }

                & > div :nth-child(1) {
                    float: right;
                    margin-top: 15px;
                    text-decoration: none;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                    width: 100px;
                    text-align: center;
                    background: #FF822D;
                    border-radius: 50px;
                    color: white;
                }
            }

            & > div:nth-child(3) {
                width: 100%;
                text-align: center;
                display: flex;
                height: 122vw;
                flex-wrap: wrap;
                position: relative;
                box-sizing: border-box;
                padding: 1%;

                & > img {
                    width: 52vw;
                    height: 52vw;
                    position: absolute;
                    cursor: pointer;
                    object-fit: contain;
                }

                & > img:nth-child(1) {
                    left: -1vw;
                    top: 0;
                }

                & > img:nth-child(2) {
                    left: 41vw;
                    top: 22vw;
                }

                & > img:nth-child(3) {
                    left: -1vw;
                    top: 48vw;
                }

                & > img:nth-child(4) {
                    left: 41vw;
                    top: 70vw;
                }
            }
        }
        .myAdvantage {
            width: 100%;
            background: url("../../image/index/2.png");
            margin-top: 30px;
            padding-bottom: 50px;

            & > div:nth-child(2) {
                display: flex;
                margin-top: 30px;
                justify-content: space-around;
                flex-wrap: wrap;

                & > div {
                    margin-top: 25px;
                    width: 45vw;
                    height: 60vw;
                    border-radius: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    position: relative;

                    & > img {
                        width: 14vw;
                        height: 14vw;
                        margin-top: 7vw;
                    }

                    & > div:nth-child(2) {
                        width: 100%;
                        position: absolute;
                        top: 26vw;
                        font-size: 16px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: rgba(255, 255, 255, 1);
                        line-height: 14px;
                    }

                    & > div:nth-child(3) {
                        width: 100%;
                        position: absolute;
                        top: 30vw;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        padding: 2vw;
                        box-sizing: border-box;

                        & > div {
                            text-align: left;
                            width: 233px;
                            font-size: 12px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 1);
                            line-height: 16px;
                        }
                    }

                }

                & > div:nth-child(1) {
                    background: rgba(224, 186, 64, 1);
                }

                & > div:nth-child(2) {
                    background: rgba(236, 121, 117, 1);
                }

                & > div:nth-child(3) {
                    background: rgba(108, 164, 190, 1);
                }

                & > div:nth-child(4) {
                    background: rgba(96, 192, 207, 1);
                }
            }
        }
        .advantageService {
            margin-top: 30px;

            & > div:nth-child(2), & > div:nth-child(4) {
                width: 100vw;
                display: flex;
                justify-content: space-between;

                & > div:nth-child(1) {
                    width: 50vw;
                    position: relative;
                    top: 10px;
                    box-sizing: border-box;
                    padding: 5vw;

                    & > h3 {
                        font-size: 20px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: rgba(236, 126, 51, 1);
                        text-align: left;
                    }

                    & > div {
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 20px;
                        text-align: left;
                    }
                }

                & > div:nth-child(2) {
                    img {
                        width: 50vw;
                        object-fit: contain;
                    }
                }
            }

            & > div:nth-child(3) {
                width: 100vw;
                display: flex;
                justify-content: space-between;

                & > div:nth-child(1) {
                    img {
                        width: 50vw;
                        object-fit: contain;
                    }
                }

                & > div:nth-child(2) {
                    position: relative;
                    top: 10px;
                    box-sizing: border-box;
                    padding: 5vw;

                    & > h3 {
                        font-size: 18px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: rgba(236, 126, 51, 1);
                        text-align: left;
                    }

                    & > div {
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 20px;
                        text-align: left;
                    }
                }
            }
        }
        .about {
            width: 100%;
            background: url("../../image/index/4.png");
            border-top: 0.1px solid transparent;
            height: 650px;

            & > div:nth-child(1) {
                margin-top: 30px;

                & > div:nth-child(1) {
                    color: rgba(255, 255, 255, 1);
                }

                & > div:nth-child(2) {
                    color: rgba(255, 185, 138, 0.2);

                    & > div:nth-child(4) {
                        color: rgba(102, 102, 102, 1);
                    }
                }

                & > div:nth-child(3) {
                    background: white;
                }
            }

            & > div:nth-child(2) {
                position: relative;

                & > div:nth-child(1) {
                    position: absolute;
                    left: 5vw;
                    width: 90vw;
                    background: rgba(255, 255, 255, 1);
                    z-index: 100;
                    top: 30px;
                    padding: 3vw;
                    box-sizing: border-box;
                    text-align: left;
                    line-height: 25px;
                    font-size: 14px;

                    & > span:nth-child(1) {
                        font-size: 18px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(236, 126, 51, 1);
                        line-height: 25px;
                    }

                    & > span:not(:first-child) {
                        text-align: left;
                        font-size: 14px;
                        color: rgba(236, 126, 51, 1);
                    }
                }

                & > img {
                    display: none;
                }

                & > div:nth-child(3) {
                    position: absolute;
                    width: 100vw;
                    top: 250px;
                    left: 0;
                    background: url("../../image/index/3.png");
                    background-size: cover;
                    height: 68vw;
                    object-fit: fill;
                }
            }
        }
        .news {
            width: 100vw;
            margin-top: 30px;
            display: flex;
            justify-content: center;

            & > img {
                display: none;
            }

            & > div:nth-child(2) {
                text-align: left;
                position: relative;

                & > h2 {
                    font-size: 15px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 24px;
                    margin-bottom: 0;
                }

                & > div {
                    text-align: left;
                }

                & > div:nth-child(2) {
                    height: 4px;
                    width: 70px;
                    background: rgba(236, 126, 51, 1);
                    left: calc(50% - 20px);
                }

                & > div:nth-child(3) {
                    margin-top: 20px;

                    & > div {
                        display: flex;
                        justify-content: space-between;
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(51, 51, 51, 1);
                        line-height: 24px;
                    }
                }
            }
        }
        .science {
            width: 100vw;
            background: url("../../image/index/5.png");
            margin-top: 30px;
            height: 450px;

            & > div:nth-child(2) {
                padding: 20px;
                margin-top: 20px;
                text-align: left;
                height: 165px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                line-height: 24px;
                text-indent: 24px
            }

            & > div:nth-child(3) {
                margin-top: 50px;
                width: 100%;
                justify-content: center;
                display: flex;
                position: relative;
                align-items: baseline;

                & > div:nth-child(even) {
                    height: 1px;
                    width: 80px;
                    background: rgba(255, 255, 255, 1);
                    position: relative;
                    top: -20px;
                }

                & > div {
                    color: white;
                    margin-left: 25px;

                    img {
                        width: 12vw;
                    }

                    div {
                        font-size: 12px;
                    }
                }
            }
        }
        .cooperation {
            margin-top: 15px;
            margin-bottom: 25px;

            & > div:nth-child(2) {
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                img {
                    width: 24%;
                    object-fit: contain;
                }
            }
        }

    }

    @media screen and (max-width: 1280px) and (min-width: 900px) {
        .index-box {
            overflow: hidden;
            // padding-bottom: 420px;
            box-sizing: border-box;
            position: relative;
        }
        .index-content{
            margin-top:50px;
        }
        .insurance {
            overflow: hidden;
            width: 70%;
            margin-left: 15%;

            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    height: 47px;
                    font-size: 48px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 130, 45, 1);
                    line-height: 24px;
                    margin-top: 72px;
                    width: 100%;
                    text-align: center;
                }

                & > div:nth-child(2) {
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    top: 94px;
                    height: 49px;
                    font-size: 64px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 185, 138, 0.2);
                    line-height: 24px;

                    & > div:nth-child(4) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 24px;
                    }
                }

                & > div:nth-child(3) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: rgba(255, 130, 45, 1);
                    margin-left: calc(50% - 29px);
                }
            }

            & > div:nth-child(2) {
                margin-top: 42px;

                & > div {
                    width: 100%;
                    height: 26px;
                    text-align: center;
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 30px;
                }

                & > div :nth-child(1) {
                    margin-top: 45px;
                    text-align: right;
                    color: red;
                    text-decoration: none;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                }
            }

            & > div:nth-child(3) {
                text-align: center;
                height: 450px;
                width: 70vw;
                position: relative;

                & > img {
                    position: absolute;
                    width: 20vw;
                    cursor: pointer;
                }

                & > img:nth-child(1) {
                    left: 1vw;
                    top: 0;
                }

                & > img:nth-child(2) {
                    left: 17vw;
                    top: 9vw
                }

                & > img:nth-child(3) {
                    left: 33vw;
                    top: 0vw
                }

                & > img:nth-child(4) {
                    left: 49vw;
                    top: 9vw
                }
            }
        }
        .myAdvantage {
            width: 100%;
            background: url("../../image/index/2.png");
            height: 710px;
            margin-top: 30px;

            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    width: 100%;
                    text-align: center;
                    height: 45px;
                    font-size: 48px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 24px;
                    margin-top: 73px;
                }

                & > div:nth-child(2) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: rgba(255, 255, 255, 1);
                    margin-left: calc(50% - 29px);
                }
            }

            & > div:nth-child(2) {
                display: flex;
                justify-content: space-around;
                margin-top: 110px;

                & > div {
                    width: 22vw;
                    height: 341px;
                    border-radius: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    position: relative;

                    & > img {
                        width: 67px;
                        height: 66px;
                        margin-top: 55px;
                    }

                    & > div:nth-child(2) {
                        width: 100%;
                        position: absolute;
                        top: 148px;
                        height: 23px;
                        font-size: 22px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: rgba(255, 255, 255, 1);
                        line-height: 14px;
                    }

                    & > div:nth-child(3) {
                        width: 100%;
                        position: absolute;
                        top: 195px;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;

                        & > div {
                            text-align: left;
                            width: 100%;
                            font-size: 14px;
                            padding: 0 10px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 1);
                            line-height: 24px;
                        }
                    }

                }

                & > div:nth-child(1) {
                    background: rgba(224, 186, 64, 1);
                }

                & > div:nth-child(2) {
                    background: rgba(236, 121, 117, 1);
                }

                & > div:nth-child(3) {
                    background: rgba(108, 164, 190, 1);
                }

                & > div:nth-child(4) {
                    background: rgba(96, 192, 207, 1);
                }
            }
        }
        .advantageService {
            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    height: 47px;
                    font-size: 48px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 130, 45, 1);
                    line-height: 24px;
                    margin-top: 72px;
                    width: 100%;
                    text-align: center;
                }

                & > div:nth-child(2) {
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    top: 94px;
                    height: 49px;
                    font-size: 64px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 185, 138, 0.2);
                    line-height: 24px;

                    & > div:nth-child(4) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 24px;
                    }
                }

                & > div:nth-child(3) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: rgba(255, 130, 45, 1);
                    margin-left: calc(50% - 29px);
                }
            }

            & > div:nth-child(2), & > div:nth-child(4) {
                width: 800px;
                margin-left: calc(50% - 400px);
                display: flex;
                justify-content: space-between;

                & > div:nth-child(1) {
                    position: relative;
                    top: 50px;

                    & > h3 {
                        font-size: 30px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: rgba(236, 126, 51, 1);
                        text-align: left;
                    }

                    & > div {
                        font-size: 18px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 30px;
                        text-align: left;
                    }
                }

            }

            & > div:nth-child(3) {
                width: 800px;
                margin-left: calc(50% - 400px);
                display: flex;
                justify-content: space-between;

                & > div:nth-child(2) {
                    position: relative;
                    top: 50px;

                    & > h3 {
                        font-size: 30px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: rgba(236, 126, 51, 1);
                        text-align: left;
                    }

                    & > div {

                        font-size: 18px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 30px;
                        text-align: left;
                    }
                }
            }
        }
        .about {
            width: 100%;
            background: url("../../image/index/4.png");
            margin-top: 30px;
            height: 600px;
            overflow: hidden;

            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    height: 47px;
                    font-size: 48px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 24px;
                    margin-top: 72px;
                    width: 100%;
                    text-align: center;
                }

                & > div:nth-child(2) {
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    top: 94px;
                    height: 49px;
                    font-size: 64px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 185, 138, 0.2);
                    line-height: 24px;

                    & > div:nth-child(4) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 24px;
                    }
                }

                & > div:nth-child(3) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: white;
                    margin-left: calc(50% - 29px);
                }
            }

            & > div:nth-child(2) {
                width: 100%;
                margin-top: 82px;
                position: relative;

                & > div:nth-child(1) {
                    position: absolute;
                    top: 30px;
                    left: 10%;
                    width: 50%;
                    background: rgba(255, 255, 255, 1);
                    z-index: 100;
                    padding: 22px;
                    box-sizing: border-box;
                    text-align: left;
                    line-height: 30px;
                    font-size: 14px;

                    & > span:nth-child(1) {
                        font-size: 24px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(236, 126, 51, 1);
                        line-height: 36px;
                    }

                    & > span:not(:first-child) {
                        text-align: left;
                        font-size: 18px;

                        color: rgba(236, 126, 51, 1);
                    }
                }

                & > img {
                    width: 50vw;
                    position: absolute;
                    right: 10%;
                }
            }
        }
        .news {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 60px;
            & > img {
                width: 50vm;
                margin-top: 98px;
            }

            & > div:nth-child(2) {
                margin-top: 150px;
                margin-left: 15px;
                & > h2 {
                    width: 150px;
                    height: 29px;
                    font-size: 30px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 24px;
                    margin-bottom: 0;
                }

                & > div {
                    text-align: left;
                }

                & > div:nth-child(2) {
                    width: 136px;
                    height: 4px;
                    background: rgba(236, 126, 51, 1);
                    margin-top: 11px;
                }

                & > div:nth-child(3) {
                    margin-top: 36px;
                    width: 100%;
                    & > div {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(51, 51, 51, 1);
                        line-height: 48px;
                    }
                }
            }
        }
        .science {
            width: 100%;
            background: url("../../image/index/5.png");
            margin-top: 30px;
            height: 610px;
            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    width: 100%;
                    text-align: center;
                    height: 45px;
                    font-size: 48px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 24px;
                    margin-top: 73px;
                }

                & > div:nth-child(2) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: rgba(255, 255, 255, 1);
                    margin-left: calc(50% - 29px);
                }
            }

            & > div:nth-child(2) {
                margin-top: 63px;
                text-align: left;
                margin-left: 10%;
                width: 80%;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                line-height: 38px;
            }

            & > div:nth-child(3) {
                margin-top: 60px;
                width: 100%;
                justify-content: center;
                display: flex;
                position: relative;
                align-items: baseline;

                & > div:nth-child(even) {
                    width: 124px;
                    height: 1px;
                    background: rgba(255, 255, 255, 1);
                    position: relative;
                    top: -25px;
                }

                & > div {
                    color: white;
                    margin-left: 25px;
                }
            }
        }
        .cooperation {
            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    height: 47px;
                    font-size: 48px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 130, 45, 1);
                    line-height: 24px;
                    margin-top: 72px;
                    width: 100%;
                    text-align: center;
                }

                & > div:nth-child(2) {
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    top: 94px;
                    height: 49px;
                    font-size: 64px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 185, 138, 0.2);
                    line-height: 24px;

                    & > div:nth-child(4) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 24px;
                    }
                }

                & > div:nth-child(3) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: rgba(255, 130, 45, 1);
                    margin-left: calc(50% - 29px);
                }
            }

            & > div:nth-child(2) {
                margin-top: 68px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 70%;
                margin-left: 15%;
                margin-bottom: 118px;
                img{
                    margin:15px 15px;
                    border:1px solid #eee;
                }
            }
        }

    }

    @media screen and (min-width: 1280px) {
        .index-box {
            // padding-bottom: 420px;
            box-sizing: border-box;
            position: relative;
        }
        .index-content{
            margin-top:60px;
        }
        .insurance {
            width: 70%;
            margin-left: 15%;

            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    height: 47px;
                    font-size: 48px;
                    font-family: "MicrosoftYaHei", "微软雅黑";
                    font-weight: 400;
                    color: rgba(255, 130, 45, 1);
                    line-height: 24px;
                    margin-top: 72px;
                    width: 100%;
                    text-align: center;
                }

                & > div:nth-child(2) {
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    top: 94px;
                    height: 49px;
                    font-size: 64px;
                    font-family: "MicrosoftYaHei", "微软雅黑";
                    font-weight: 400;
                    color: rgba(255, 185, 138, 0.2);
                    line-height: 24px;

                    & > div:nth-child(4) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 24px;
                    }
                }

                & > div:nth-child(3) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: rgba(255, 130, 45, 1);
                    margin-left: calc(50% - 29px);
                }
            }

            & > div:nth-child(2) {
                margin-top: 42px;

                & > div {
                    width: 100%;
                    height: 26px;
                    text-align: center;
                    font-size: 18px;
                    font-family: "MicrosoftYaHei", "微软雅黑";
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 30px;
                }

                & > div :nth-child(1) {
                    margin-top: 45px;
                    text-align: right;
                    color: red;
                    text-decoration: none;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    font-size: 14px;
                    font-family: "MicrosoftYaHei", "微软雅黑";
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                }
            }

            & > div:nth-child(3) {
                text-align: center;
                height: 450px;
                width: 1040px;
                margin-left: calc(50% - 520px);
                position: relative;

                & > img {
                    position: absolute;
                    width: 320px;
                    cursor: pointer;
                }

                & > img:nth-child(1) {
                    left: 0;
                    top: 0;
                }

                & > img:nth-child(2) {
                    left: 240px;
                    top: 140px;
                }

                & > img:nth-child(3) {
                    left: 480px;
                    top: 0px;
                }

                & > img:nth-child(4) {
                    left: 720px;
                    top: 140px;
                }
            }
        }
        .myAdvantage {
            width: 100%;
            background: url("../../image/index/2.png");
            height: 710px;
            margin-top: 30px;

            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    width: 100%;
                    text-align: center;
                    height: 45px;
                    font-size: 48px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 24px;
                    margin-top: 73px;
                }

                & > div:nth-child(2) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: rgba(255, 255, 255, 1);
                    margin-left: calc(50% - 29px);
                }
            }

            & > div:nth-child(2) {
                display: flex;
                justify-content: center;
                margin-top: 110px;

                & > div {
                    width: 281px;
                    height: 341px;
                    border-radius: 20px;
                    margin-left: 25px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    position: relative;

                    & > img {
                        width: 67px;
                        height: 66px;
                        margin-top: 55px;
                    }

                    & > div:nth-child(2) {
                        width: 100%;
                        position: absolute;
                        top: 148px;
                        height: 23px;
                        font-size: 24px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: rgba(255, 255, 255, 1);
                        line-height: 14px;
                    }

                    & > div:nth-child(3) {
                        width: 100%;
                        position: absolute;
                        top: 195px;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;

                        & > div {
                            text-align: left;
                            width: 233px;
                            font-size: 15px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 1);
                            line-height: 24px;
                        }
                    }

                }

                & > div:nth-child(1) {
                    background: rgba(224, 186, 64, 1);
                }

                & > div:nth-child(2) {
                    background: rgba(236, 121, 117, 1);
                }

                & > div:nth-child(3) {
                    background: rgba(108, 164, 190, 1);
                }

                & > div:nth-child(4) {
                    background: rgba(96, 192, 207, 1);
                }
            }
        }
        .advantageService {
            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    height: 47px;
                    font-size: 48px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 130, 45, 1);
                    line-height: 24px;
                    margin-top: 72px;
                    width: 100%;
                    text-align: center;
                }

                & > div:nth-child(2) {
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    top: 94px;
                    height: 49px;
                    font-size: 64px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 185, 138, 0.2);
                    line-height: 24px;

                    & > div:nth-child(4) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 24px;
                    }
                }

                & > div:nth-child(3) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: rgba(255, 130, 45, 1);
                    margin-left: calc(50% - 29px);
                }
            }

            & > div:nth-child(2), & > div:nth-child(4) {
                width: 800px;
                margin-left: calc(50% - 400px);
                display: flex;
                justify-content: space-between;

                & > div:nth-child(1) {
                    position: relative;
                    top: 50px;

                    & > h3 {
                        font-size: 30px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: rgba(236, 126, 51, 1);
                        text-align: left;
                    }

                    & > div {
                        font-size: 18px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 30px;
                        text-align: left;
                    }
                }

            }

            & > div:nth-child(3) {
                width: 800px;
                margin-left: calc(50% - 400px);
                display: flex;
                justify-content: space-between;

                & > div:nth-child(2) {
                    position: relative;
                    top: 50px;

                    & > h3 {
                        font-size: 30px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: rgba(236, 126, 51, 1);
                        text-align: left;
                    }

                    & > div {

                        font-size: 18px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 30px;
                        text-align: left;
                    }
                }
            }
        }
        .about {
            width: 100%;
            background: url("../../image/index/4.png");
            margin-top: 30px;
            height: 818px;
            overflow: hidden;

            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    height: 47px;
                    font-size: 48px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 24px;
                    margin-top: 72px;
                    width: 100%;
                    text-align: center;
                }

                & > div:nth-child(2) {
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    top: 94px;
                    height: 49px;
                    font-size: 64px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 185, 138, 0.2);
                    line-height: 24px;

                    & > div:nth-child(4) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 24px;
                    }
                }

                & > div:nth-child(3) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: white;
                    margin-left: calc(50% - 29px);
                }
            }

            & > div:nth-child(2) {
                display: flex;
                width: 1400px;
                margin-top: 82px;
                margin-left: calc(50% - 700px);
                justify-content: center;
                position: relative;

                & > div:nth-child(1) {
                    position: absolute;
                    left: 0;
                    width: 599px;
                    height: 323px;
                    background: rgba(255, 255, 255, 1);
                    z-index: 100;
                    top: 100px;
                    padding: 42px;
                    box-sizing: border-box;
                    text-align: left;
                    line-height: 36px;
                    font-size: 18px;

                    & > span:nth-child(1) {
                        font-size: 24px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(236, 126, 51, 1);
                        line-height: 36px;
                    }

                    & > span:not(:first-child) {
                        text-align: left;
                        font-size: 18px;
                        color: rgba(236, 126, 51, 1);
                    }
                }

                & > img {
                    width: 50vw;
                    position: absolute;
                    right: 50px;
                }
            }
        }
        .news {
            width: 100%;
            margin-top: 30px;
            display: flex;
            justify-content: center;
            margin-bottom: 110px;

            & > img {
                width: 555px;
                height: 464px;
                margin-top: 98px;
            }

            & > div:nth-child(2) {
                margin-top: 178px;
                margin-left: 45px;

                & > h2 {
                    width: 150px;
                    height: 29px;
                    font-size: 30px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 24px;
                    margin-bottom: 0;
                }

                & > div {
                    text-align: left;
                }

                & > div:nth-child(2) {
                    width: 136px;
                    height: 4px;
                    background: rgba(236, 126, 51, 1);
                    margin-top: 11px;
                }

                & > div:nth-child(3) {
                    margin-top: 66px;

                    & > div {
                        width: 600px;
                        display: flex;
                        justify-content: space-between;
                        font-size: 18px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(51, 51, 51, 1);
                        line-height: 48px;
                    }
                }
            }
        }
        .science {
            width: 100%;
            background: url("../../image/index/5.png");
            margin-top: 30px;
            height: 695px;

            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    width: 100%;
                    text-align: center;
                    height: 45px;
                    font-size: 48px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 24px;
                    margin-top: 73px;
                }

                & > div:nth-child(2) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: rgba(255, 255, 255, 1);
                    margin-left: calc(50% - 29px);
                }
            }

            & > div:nth-child(2) {
                margin-left: calc(50% - 530px);
                margin-top: 63px;
                text-align: left;
                width: 1061px;
                height: 165px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                line-height: 48px;
            }

            & > div:nth-child(3) {
                margin-top: 100px;
                width: 100%;
                justify-content: center;
                display: flex;
                position: relative;
                align-items: baseline;

                & > div:nth-child(even) {
                    width: 124px;
                    height: 1px;
                    background: rgba(255, 255, 255, 1);
                    position: relative;
                    top: -25px;
                }

                & > div {
                    color: white;
                    margin-left: 25px;
                }
            }
        }
        .cooperation {
            & > div:nth-child(1) {
                width: 100%;
                text-align: center;
                position: relative;
                border-top: 0.1px solid transparent;

                & > div:nth-child(1) {
                    height: 47px;
                    font-size: 48px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 130, 45, 1);
                    line-height: 24px;
                    margin-top: 72px;
                    width: 100%;
                    text-align: center;
                }

                & > div:nth-child(2) {
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    top: 94px;
                    height: 49px;
                    font-size: 64px;
                    font-family: STHeiti;
                    font-weight: 400;
                    color: rgba(255, 185, 138, 0.2);
                    line-height: 24px;

                    & > div:nth-child(4) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 24px;
                    }
                }

                & > div:nth-child(3) {
                    position: absolute;
                    width: 58px;
                    height: 4px;
                    background: rgba(255, 130, 45, 1);
                    margin-left: calc(50% - 29px);
                }
            }

            & > div:nth-child(2) {
                margin-top: 68px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 70%;
                margin-left: 15%;
                margin-bottom: 118px;

                img{
                    margin:0 15px;
                    border:1px solid #eee;
                }
            }
        }
    }
</style>
