<template>
  <div class="bottom" id="contact">
    <div>
      <div>
        <div>
          <img src="../../image/bottom/logo.png" alt="">
        </div>
        <div>
          <div>保险产品服务</div>
          <!-- <div @click="routerTolist('006141')" style="cursor: pointer">物流行业雇主险</div>
          <div @click="routerTodetail('006142')" style="cursor: pointer">一般制造业雇主险</div>
          <div @click="routerTodetail('006152')" style="cursor: pointer">科技、设计、服务行业雇主险</div>
          <div @click="routerTolist('006151')" style="cursor: pointer">电信电力行业雇主险</div> -->
          <div style="cursor: pointer">物流行业雇主险</div>
          <div style="cursor: pointer">一般制造业雇主险</div>
          <div style="cursor: pointer">科技、设计、服务行业雇主险</div>
          <div style="cursor: pointer">电信电力行业雇主险</div>
        </div>
        <div>
          <div>关于我们</div>
          <div>关于锐鹿保</div>
          <div>锐鹿新闻</div>
          <div>加入我们</div>
          <!-- <a href="https://api.kuaidi100.com/" target="_blank" rel="noopener noreferrer" style="text-align: left;display: block;color: #BCBDC4;text-decoration: none;">快递100</a> -->
        </div>
        <div>
          <div>联系我们</div>
          <div>客服热线：400-616-2199</div>
          <div>商务合作：</div>
          <div>孙经理：18617600999</div>
          <div>联系地址：</div>
          <div style="line-height: 1.5;">河北省衡水市桃城区前进街与隆兴路交叉口泰华大厦19层</div>
        </div>
        <div>
          <div>联系地址：</div>
          <div>河北省衡水市桃城区前进街与永兴路交叉口财富大厦11层</div>
        </div>
      </div>
      <div>
        <span>关注我们</span>
        <div>
          <img src="../../image/bottom/gongzhonghao.png" alt="">
          <div>微信公众号</div>
        </div>
        <div>
          <img src="../../image/bottom/xiaochengxu.png" alt="">
          <div>小程序</div>
        </div>
      </div>
    </div>
    <div>
      <div>©Copyright2018-2020河北锐鹿网络科技有限公司</div>
      <div><a href="http://beian.miit.gov.cn/" target="_blank" style="color:inherit;text-decoration: none"><img src="../../image/bottom/all_ba.png" alt="" style="vertical-align: middle;"> 互联网站ICP备案 冀ICP备18038809号-2</a> <a href="https://beian.mps.gov.cn/#/query/webSearch?code=13110202001390" rel="noreferrer" target="_blank" style="color:inherit;text-decoration: none"><img src="../../image/gh.png" alt="" style="vertical-align: middle;width:15px;"> 冀公网安备13110202001390</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "frontBottom",
  methods:{
     routerTolist: function (pro_num) {//有工种页转跳列表页
        this.$router.push({
            name: 'employerInsurance',
            query: {
                pro_num: pro_num,
            }
        })
    },
    routerTodetail: function (pro_num) {//没有工种页转跳详情页
        this.$router.push({
            name: 'detail',
            query: {
                pro_num: pro_num,
            }
        })
    },
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 900px) {
  .bottom {
    // position: absolute;
    // bottom: 0;
    width: 100vw;
    background: #000;

    & > div:nth-child(1) {
      width: 100vw;
      box-sizing: border-box;
      background: #222222;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      & > div:nth-child(1) {
        width: 100vw;
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;

        & > div {
          width: 33vw;
          object-fit: contain;
        }

        & > div:nth-child(1) {
          img {
            position: relative;
            top: -30px;
            width: 70%;
            margin-left: 15%;
          }

          margin-top: -30px;
          text-align: left;
          position: relative;
          top: 80px;
        }

        & > div:nth-child(2), & > div:nth-child(3) {
          line-height: 24px;

          & > div {
            text-align: left;
            color: #BCBDC4;
            font-size: 12px;
            font-family: PingFangSC-Regular;
          }

          & > div:nth-child(1) {
            color: #FFFFFF;
          }
        }

        & > div:nth-child(4) {
          margin-top: 10px;
          line-height: 24px;
          margin-left: 33vw;

          & > div {
            text-align: left;
            font-size: 12px;
            color: #BCBDC4;
            font-family: PingFangSC-Regular;
          }

          & > div:nth-child(1), & > div:nth-child(3) {
            color: #FFFFFF;
          }

          & > div:nth-child(4), & > div:nth-child(3) {
            display: none;
          }
        }

        & > div:nth-child(5) {
          margin-top: 10px;
          line-height: 24px;
          color: #BCBDC4;

          & > div {
            text-align: left;
            font-size: 12px;
            font-family: PingFangSC-Regular;
          }

          & > div:nth-child(1), & > div:nth-child(3) {
            color: #FFFFFF;
          }
        }

      }

      & > div:nth-child(2) {
        position: relative;
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        padding-top: 20px;
        width: 100vw;
        border-top: 1px solid rgba(65, 67, 74, 1);
        margin-bottom: 15px;
        & > div {
          margin-left: 33px;
          color: white;
          margin-top: 47px;
        }

        & > span {
          position: absolute;
          font-size: 16px;
          left: 33px;
          font-family: STHeiti;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          line-height: 24px;
        }
      }
    }
    & > div:nth-child(2) {
      //box-sizing: border-box;
      padding: 10px 10%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(99, 101, 108, 1);
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 1280px) and (min-width: 900px) {
  .bottom {
    // position: absolute;
    // bottom: 0;
    width: 100%;
    height: 420px;
    background: #000;

    & > div:nth-child(1) {
      width: 100%;
      box-sizing: border-box;
      height: 266px;
      padding: 0 10%;
      background: #222222;
      display: flex;
      align-items: center;

      & > div:nth-child(1) {
        width: 80%;
        display: flex;
        align-items: baseline;

        & > div {
          width: 25%;
        }

        & > div:nth-child(1) {
          text-align: left;
          margin-top: -90px;
          position: relative;
          top: 80px;
        }

        & > div:nth-child(2), & > div:nth-child(3) {
          line-height: 36px;

          & > div {
            text-align: left;
            color: #BCBDC4;
            font-size: 12px;
            font-family: PingFangSC-Regular;
          }

          & > div:nth-child(1) {
            color: #fff;
          }
        }

        & > div:nth-child(5) {
          display: none;
        }

        & > div:nth-child(4) {
          line-height: 36px;

          & > div {
            text-align: left;
            color: #BCBDC4;
            font-size: 12px;
            font-family: PingFangSC-Regular;
          }

          & > div:nth-child(1), & > div:nth-child(3),& > div:nth-child(5) {
            color: #FFFFFF;
          }
        }

      }

      & > div:nth-child(2) {
        position: relative;
        display: flex;
        justify-content: flex-start;
        width: 30%;
        border-left: 1px solid rgba(65, 67, 74, 1);

        & > div {
          margin-left: 33px;
          color: white;
          margin-top: 47px;
        }

        & > span {
          position: absolute;
          font-size: 16px;
          left: 33px;
          font-family: STHeiti;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          line-height: 24px;
        }
      }
    }

    & > div:nth-child(2) {
      box-sizing: border-box;
      padding: 0 15%;
      height: 156px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(99, 101, 108, 1);
      line-height: 24px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .bottom {
    // position: absolute;
    // bottom: 0;
    width: 100%;
    height: 422px;
    background: #000;

    & > div:nth-child(1) {
      width: 100%;
      box-sizing: border-box;
      height: 300px;
      padding: 0 15%;
      background: #222222;
      display: flex;
      align-items: center;

      & > div:nth-child(1) {
        width: 70%;
        display: flex;
        align-items: baseline;

        & > div {
          width: 25%;
        }

        & > div:nth-child(1) {
          text-align: left;
          margin-top: -90px;
          position: relative;
          top: 80px;
        }

        & > div:nth-child(2), & > div:nth-child(3) {
          line-height: 36px;

          & > div {
            text-align: left;
            color: #BCBDC4;
            font-size: 14px;
            font-family: PingFangSC-Regular;
          }

          & > div:nth-child(1) {
            color: #fff;
            font-size: 16px;
          }
        }

        & > div:nth-child(5) {
          display: none;
        }

        & > div:nth-child(4) {
          line-height: 36px;

          & > div {
            text-align: left;
            color: #BCBDC4;
            font-size: 14px;
            font-family: PingFangSC-Regular;
          }

          & > div:nth-child(1), & > div:nth-child(3), & > div:nth-child(5)  {
            color: #fff;
            font-size: 16px;
          }
        }

        & > div:nth-child(5) {
          display: none;
        }

      }

      & > div:nth-child(2) {
        position: relative;
        display: flex;
        justify-content: flex-start;
        width: 30%;
        border-left: 1px solid rgba(65, 67, 74, 1);

        & > div {
          margin-left: 33px;
          color: white;
          margin-top: 47px;
        }

        & > span {
          position: absolute;
          font-size: 16px;
          left: 33px;
          font-family: STHeiti;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          line-height: 24px;
        }
      }
    }

    & > div:nth-child(2) {
      box-sizing: border-box;
      padding: 0 15%;
      height: 122px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(99, 101, 108, 1);
      line-height: 24px;
    }
  }
}
</style>
