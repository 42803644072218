<template>
    <div class="box"
         :style="'background:'+background + (positionShow?';position:fixed;top:0;z-index:999;width:100%;':'') + (box_shadow?'box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.05)':'')">
        <div class="front-header">
            <img v-if="logo==0" src="../../image/login/logo.png" alt="" @click="tapToggle('index')">
            <img v-else src="../../image/login/logo-1.png" alt="" @click="tapToggle('index')">
            <a href="javascript:;" @click="tapToggle('index')"
               :style="(C_chooseTitle==='index')?(';border-bottom:2px solid ' + fontcolor):''"
               :class="C_chooseTitle==='index'?'chooseIt':''"><span :style="'color:' + fontcolor">首页</span></a>
            <a href="javascript:;" @click="tapToggle('employer')"
               :style="(C_chooseTitle==='employer')?(';border-bottom:2px solid ' + fontcolor):''"
               :class="C_chooseTitle==='employer'?'chooseIt':''"><span :style="'color:' + fontcolor">雇主险产品</span></a>
            <a href="javascript:;" @click="tapToggle('advantage')"
               :style="(C_chooseTitle==='advantage')?';border-bottom:2px solid ' + fontcolor:''"
               :class="C_chooseTitle==='advantage'?'chooseIt':''"><span :style="'color:' + fontcolor">服务优势</span></a>
            <a href="javascript:;" @click="tapToggle('about')"
               :style="(C_chooseTitle==='about')?';border-bottom:2px solid ' + fontcolor:''"
               :class="C_chooseTitle==='about'?'chooseIt':''"><span :style="'color:' + fontcolor">关于锐鹿</span></a>
            <a href="javascript:;" @click="tapToggle('science')"
               :style="(C_chooseTitle==='science')?';border-bottom:2px solid ' + fontcolor:''"
               :class="C_chooseTitle==='science'?'chooseIt':''"><span :style="'color:' + fontcolor">科技保险</span></a>
            <!-- <a href="javascript:;" @click="tapToggle('cooperation')"
               :style="(C_chooseTitle==='cooperation')?';border-bottom:2px solid ' + fontcolor:''"
               :class="C_chooseTitle==='cooperation'?'chooseIt':''"><span :style="'color:' + fontcolor">合作伙伴</span></a> -->
            <a href="javascript:;" @click="tapToggle('contact')"
               :style="(C_chooseTitle==='contact')?';border-bottom:2px solid ' + fontcolor:''"
               :class="C_chooseTitle==='contact'?'chooseIt':''"><span :style="'color:' + fontcolor">联系我们</span></a>
            <button :style="'border-color:' + fontcolor+';color:' + fontcolor" @click="goLogin()">{{ login_text }}
            </button>
        </div>
        <div class="front-header-1">
            <div>
                <img v-if="logo==0" src="../../image/login/logo.png" alt="">
                <img v-else src="../../image/login/logo-1.png" alt="">
                <div class="iconfont" @click="headerShow">&#xe6ae;</div>
            </div>
            <div class="front-header-1-menu" :style="headerHeight?'height:289px;':'height:0'">
                <a href="javascript:;" @click="tapToggle('index')"
                   :class="C_chooseTitle==='index'?'chooseIt':''"><span :style="'color:' + fontcolor">首页</span></a>
                <a href="javascript:;" @click="tapToggle('employer')"
                   :class="C_chooseTitle==='employer'?'chooseIt':''"><span
                        :style="'color:' + fontcolor">雇主险产品</span></a>
                <a href="javascript:;" @click="tapToggle('advantage')"
                   :class="C_chooseTitle==='advantage'?'chooseIt':''"><span
                        :style="'color:' + fontcolor">服务优势</span></a>
                <a href="javascript:;" @click="tapToggle('about')"
                   :class="C_chooseTitle==='about'?'chooseIt':''"><span :style="'color:' + fontcolor">关于锐鹿</span></a>
                <a href="javascript:;" @click="tapToggle('science')"
                   :class="C_chooseTitle==='science'?'chooseIt':''"><span :style="'color:' + fontcolor">科技保险</span></a>
                <a href="javascript:;" @click="tapToggle('cooperation')"
                   :class="C_chooseTitle==='cooperation'?'chooseIt':''"><span :style="'color:' + fontcolor">合作伙伴</span></a>
                <a href="javascript:;" @click="tapToggle('contact')"
                   :class="C_chooseTitle==='contact'?'chooseIt':''"><span :style="'color:' + fontcolor">联系我们</span></a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "frontHeader",
        props: {
            chooseTitle: {
                default: 'index',
            },
            background: {
                default: "#1553B1"
            },
            positionShow: {
                default: false
            },
            fontcolor: {
                default: "#fff"
            },
            logo: {
                default: '0'
            }
        },
        data() {
            return {
                login_text: "登录",
                box_shadow: false,//阴影
                C_chooseTitle: "",
                headerHeight: false
            }
        },
        created() {
            this.login_type = this.$store.state.login_type
            if (this.login_type != 1) {
                this.login_text = "登录"
            } else {
                this.login_text = "个人中心"
            }
            this.C_chooseTitle = this.chooseTitle
        },
        mounted() {
            // 事件监听滚动条
            window.addEventListener("scroll", this.shadowScroll);
        },
        methods: {
            goLogin: function () {//点击登陆、个人中心
                if (this.login_type != 1) {
                    this.$router.push({name: 'login'})
                } else {
                    this.$router.push({name: 'guaranteeSlip'})
                }
            },
            shadowScroll: function () {//滚动添加阴影
                let offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                if (offset > 50) {
                    this.box_shadow = true
                } else {
                    this.box_shadow = false
                }
            },
            //点击切换
            tapToggle: function (e) {
                this.C_chooseTitle = e
                let routeName = this.$route.name
                let anchorElement = document.getElementById(e);
                if (routeName == "index") {
                    if (anchorElement) {
                        anchorElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'});
                    } else {
                        // this.$router.push({name: 'employerInsurance'})
                    }
                } else {
                    if (e != "employer") {
                        this.$router.push({
                            name: 'index',
                            params: {
                                present: e
                            }
                        })
                    }else{
                        // this.$router.push({name: 'employerInsurance'})
                    }
                }
            },
            headerShow() {
                this.headerHeight = !this.headerHeight
            }
        }
    }
</script>

<style scoped lang="scss">
    @media screen and (max-width: 900px) {
        .front-header {
            display: none;
        }
        .front-header-1 {
            /*background: rgba(255, 255, 255, 0.7);*/
            & > div:nth-child(1) {
                display: flex;
                padding: 10px;
                justify-content: space-between;

                img {
                    object-fit: contain;
                    height: 30px;
                }

                & > div {
                    font-size: 30px;
                    color: orange;
                    line-height: 30px;
                }
            }

            .front-header-1-menu {
                width: 100%;
                overflow: hidden;
                transition: height 0.5s;
                box-sizing: border-box;
                padding: 0 30px;

                a {
                    display: block;
                    font-size: 20px;
                    text-align: left;
                    text-decoration: none;
                    line-height: 40px;
                }
            }
        }

    }

    @media screen and (max-width: 1280px) and (min-width: 900px) {
        .front-header-1 {
            display: none;
        }
        .front-header {
            display: flex;
            padding: 10px 0;
            width: 80%;
            margin-left: 10%;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            img {
                object-fit: contain;
                height: 30px;
            }
            & > a {
                color: #333;
                text-decoration: none;
                font-size: 14px;
            }

            & > button {
                background-color: transparent;
                width: 70px;
                height: 34px;
                border: 1px solid #333;
                color: #333;
                border-radius: 5px;
            }

            .chooseIt {
                position: relative;
                top: 3px;
                padding-bottom: 4px;
                border-bottom: 2px solid #333;
            }
        }
    }

    @media screen and (min-width: 1280px) {
        .front-header-1 {
            display: none;
        }
        .box {
            background-color: #0053B9;
        }
        .front-header {
            display: flex;
            padding: 10px 0;
            width: 70%;
            margin-left: 15%;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;

            & > a {
                color: #333;
                text-decoration: none;
            }

            & > button {
                background-color: transparent;
                width: 70px;
                height: 34px;
                border: 1px solid #333;
                color: #333;
                border-radius: 5px;
                cursor: pointer;
            }

            .chooseIt {
                position: relative;
                top: 3px;
                padding-bottom: 4px;
                border-bottom: 2px solid #333;
            }
        }
    }
</style>
